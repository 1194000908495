// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atrakcje-js": () => import("./../../../src/pages/atrakcje.js" /* webpackChunkName: "component---src-pages-atrakcje-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kadra-js": () => import("./../../../src/pages/kadra.js" /* webpackChunkName: "component---src-pages-kadra-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-logowanie-js": () => import("./../../../src/pages/logowanie.js" /* webpackChunkName: "component---src-pages-logowanie-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferty-js": () => import("./../../../src/pages/oferty.js" /* webpackChunkName: "component---src-pages-oferty-js" */),
  "component---src-pages-opinie-js": () => import("./../../../src/pages/opinie.js" /* webpackChunkName: "component---src-pages-opinie-js" */),
  "component---src-pages-osrodki-js": () => import("./../../../src/pages/osrodki.js" /* webpackChunkName: "component---src-pages-osrodki-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-rezerwacja-js": () => import("./../../../src/pages/rezerwacja.js" /* webpackChunkName: "component---src-pages-rezerwacja-js" */),
  "component---src-pages-strefa-rodzica-dokumenty-do-pobrania-js": () => import("./../../../src/pages/strefa-rodzica/dokumenty-do-pobrania.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-dokumenty-do-pobrania-js" */),
  "component---src-pages-strefa-rodzica-js": () => import("./../../../src/pages/strefa-rodzica.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-js" */),
  "component---src-pages-strefa-rodzica-odpowiedzi-na-pytania-rodzicow-js": () => import("./../../../src/pages/strefa-rodzica/odpowiedzi-na-pytania-rodzicow.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-odpowiedzi-na-pytania-rodzicow-js" */),
  "component---src-pages-strefa-rodzica-promocje-js": () => import("./../../../src/pages/strefa-rodzica/promocje.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-promocje-js" */),
  "component---src-pages-strefa-rodzica-warunki-uczestnictwa-js": () => import("./../../../src/pages/strefa-rodzica/warunki-uczestnictwa.js" /* webpackChunkName: "component---src-pages-strefa-rodzica-warunki-uczestnictwa-js" */),
  "component---src-pages-twoje-konto-js": () => import("./../../../src/pages/twoje-konto.js" /* webpackChunkName: "component---src-pages-twoje-konto-js" */),
  "component---src-pages-wyslij-zapytanie-js": () => import("./../../../src/pages/wyslij-zapytanie.js" /* webpackChunkName: "component---src-pages-wyslij-zapytanie-js" */),
  "component---src-pages-zresetuj-haslo-js": () => import("./../../../src/pages/zresetuj-haslo.js" /* webpackChunkName: "component---src-pages-zresetuj-haslo-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-kadra-js": () => import("./../../../src/templates/kadra.js" /* webpackChunkName: "component---src-templates-kadra-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-resort-js": () => import("./../../../src/templates/resort.js" /* webpackChunkName: "component---src-templates-resort-js" */)
}

